import React from 'react'
import styled from '@emotion/styled'
import { bool, node, string } from 'prop-types'
import { makeImgResizerUrl, makeImgResizeBlurUrl } from '@jelly/utils'

//IMPORT COMPONENTS
import { Hero } from '@jelly/components'

// Styled Components via Emotion
const SectionWrapper = styled.section`
	height: ${({ height }) => height || '75vh'};
	min-height: ${({ height }) => height || '425px'};
	position: relative;
	width: 100%;

	${({ theme }) => theme.MQ.L} {
		height: ${({ height }) => height || '100vh'};
		${({ height }) => !height && 'min-height: 669px;'};
	}

	${({ theme }) => theme.MQ.L} and (min-height: 1000px) {
		height: ${({ height }) => height || '75vh'};
	}
`
const HeroImg = styled.div`
	background-color: ${({ theme }) => theme.colors.teal};
	background-size: cover;
	background-position: ${({ bgPosition }) => bgPosition || 'top center'};
	background-repeat: no-repeat;
	height: inherit;
	min-height: ${({ height }) => height || '425px'};
	width: 100%;

	${({ theme }) => theme.MQ.L} {
		${({ height }) => !height && 'min-height: 669px;'};
		background-size: cover;
		background-position: ${({ bgPosition }) => bgPosition || 'top center'};
		background-repeat: no-repeat;

		&.lazyloaded {
			background-image: url(${({ background }) => background});
		}
	}

	${({ theme }) => theme.MQ.MAX} {
		background-position: ${({ maxBgPosition }) => maxBgPosition || '0 50%'};
	}
`
const HeroImgOverlay = styled.div`
	background: ${({ overlayColor }) => overlayColor};
	opacity: 0;
	height: inherit;
	min-height: ${({ height }) => height || '425px'};
	position: absolute;
	top: 0;
	width: 100%;

	${({ theme }) => theme.MQ.L} {
		opacity: ${({ isBlurred, opacity }) => (isBlurred ? '.6' : opacity)};
		${({ height }) => !height && 'min-height: 669px;'};
	}
`

/**
 * This Component is the HeroBanner
 * This pulls together the Hamburger Menu, Hero and Navigation components
 * This is where we determine whether or not a Solo Search panel should slide out
 *
 * We set the background here, so the overlay doesn't impact the Hero component text
 * We also have the option to flip any image via CSS
 * (intend for it to be refactored)
 */
function HeroBanner({
	align,
	background,
	bgPosition,
	children,
	height,
	isBlurred,
	maxBgPosition,
	overlayColor,
	overlayOpacity,
	top,
}) {
	const baseBgImgOptions = {
		src: background,
		method: 'w',
		w: '1400-webp:100',
	}

	const backgroundUrl = isBlurred
		? makeImgResizeBlurUrl({ ...baseBgImgOptions, sigma: 8, radius: 8 })
		: makeImgResizerUrl(baseBgImgOptions)

	return (
		<SectionWrapper height={height}>
			<HeroImg
				background={backgroundUrl}
				bgPosition={bgPosition}
				className="lazyload"
				height={height}
				maxBgPosition={maxBgPosition}
			/>
			<HeroImgOverlay
				height={height}
				overlayColor={overlayColor}
				opacity={overlayOpacity}
				isBlurred={isBlurred}
			/>
			<Hero align={align} content={children} top={top} />
		</SectionWrapper>
	)
}

HeroBanner.propTypes = {
	align: string,
	background: string,
	bgPosition: string,
	children: node,
	height: string,
	isBlurred: bool,
	maxBgPosition: string,
	overlayColor: string,
	overlayOpacity: string,
	top: string,
}

export default HeroBanner
