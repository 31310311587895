import React, { useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { array, bool, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import { JsonLd } from 'react-schemaorg'
import Media from 'react-media'
import { useTheme } from 'emotion-theming'

// Import Components
import AboutSection from './About'
import ArticleSection from './Articles'
import ConnectWithUs from './Connect'
import CoreValues from './CoreValues'
import ExpertSection from './Experts'
import HeroBanner from 'components/HeroBanner'
import ReviewSection from './Reviews'
import TopCategories from './TopCategories'
import {
	GtmDataLayerSetup,
	EmailSubscribeContext,
	Layout,
	OrganizationSchema,
	QtWidget,
	SearchSchema,
	SiteConfigContext,
	WordListTypingAnimation,
} from '@jelly/components'

const HEADLINE_TEXT_LIST_TO_LOOP = ['HR Team', 'Finance Team', 'Tech Team', 'Marketing Team', 'Business']

const HOMEPAGE_META = {
	canonical: 'https://www.business.com',
	description: 'Get the business advice, tips, and resources you need to run and grow your business.',
	ogImage: 'https://www.business.com/images/branding/logos/block_monogram_navy.jpg',
	robots: 'index, follow',
	title: 'business.com: Expert Business Advice, Tips, and Resources',
}

const SAME_AS_SCHEMA_URLS = [
	'https://www.linkedin.com/company/business-com-/',
	'https://twitter.com/businessdotcom',
	'https://www.facebook.com/businessdotcom/',
	'https://www.instagram.com/business_dotcom/',
	'https://www.youtube.com/channel/UC39-zDjWVQVEZd856W_NP_g',
]

// Styled Components via Emotion
const HomeWrapper = styled.div`
	height: 100%;
	width: 100%;
`

// Styles for Hero Banner Text
const Connecting = styled.div`
	${({ theme }) => theme.text.sectionHeaderSmall};
	color: #fff;
	margin: 10px 0;
`
const Header1 = styled.h1`
	${({ theme }) => theme.header.h1};
	line-height: 50px;
	margin-top: 0;

	${({ theme }) => theme.MQ.M} {
		line-height: 70px;
	}

	@media (max-height: 450px) and (max-width: 830px) {
		font-size: 45px;
		margin-bottom: 25px;
	}
`

const propTypes = {
	articles: array.isRequired,
	categories: array.isRequired,
	experts: array.isRequired,
	hasVwo: bool,
	meta: object,
}
/**
 * This is the Home Page index file
 */
function HomePage({ articles, categories, env, experts, meta }) {
	const { MATCH_MEDIA } = useTheme()
	const {
		siteConstants: {
			EMAIL_CAPTURE_SOURCES: { homepage },
		},
	} = useContext(SiteConfigContext)

	const { emailSubscribeFormSubmitted, setEmailSubscribeSource, setIsScrollListenerEnabled } = useContext(
		EmailSubscribeContext
	)

	useEffect(() => {
		setEmailSubscribeSource(homepage)
	}, [homepage, setEmailSubscribeSource])

	useEffect(() => {
		// Enable scroll listener here so the Email Capture Floater Bar pops up on scroll
		!emailSubscribeFormSubmitted && setIsScrollListenerEnabled(true)

		// Clean up after ourselves
		return () => {
			setIsScrollListenerEnabled(false)
		}
	}, [emailSubscribeFormSubmitted, setIsScrollListenerEnabled])

	return (
		<HomeWrapper>
			<GtmDataLayerSetup contentType="homepage" templateType="list" />
			<JsonLd item={OrganizationSchema(meta.canonical, meta.ogImage, SAME_AS_SCHEMA_URLS)} />
			<JsonLd item={SearchSchema('https://www.business.com')} />
			<Layout meta={{ ...HOMEPAGE_META, ...meta }}>
				{/* meta data for claiming domain on pinterest--TODO: Remove once claimed */}
				<Helmet meta={[{ name: 'p:domain_verify', content: '3476916a0edc2c48dc2baaab41bef76f' }]} />
				<HeroBanner background="https://www.business.com/images/home/bdc_homepage_hero_2021.jpg">
					<Connecting>Connecting You To Opportunity</Connecting>
					<Header1>
						Take your{' '}
						<Media query={MATCH_MEDIA.M} defaultMatches={true}>
							{(matches) => (matches ? null : <br />)}
						</Media>
						[&nbsp;
						<WordListTypingAnimation textList={HEADLINE_TEXT_LIST_TO_LOOP} finalDelay={10000} />
						&nbsp;]
						<br /> to the next level
					</Header1>
				</HeroBanner>
				<CoreValues />
				<AboutSection />
				<ReviewSection reviews={categories} />
				<QtWidget qtOfferGroupId="2" />
				<TopCategories />
				<ArticleSection articles={articles} />
				<ExpertSection experts={experts} />
				<ConnectWithUs />
			</Layout>
		</HomeWrapper>
	)
}

HomePage.propTypes = propTypes
export default HomePage
